import React, { useEffect, useState } from 'react';
import Title from '../../shared/Title';
import Questionaire from '../../shared/Questionaire';
import Explanation from '../../shared/Explanation';
import ButtonBox from '../../shared/ButtonBox';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper';
import RightArrow from '../../shared/RightArrow';
import LeftArrow from '../../shared/LeftArrow';
import './booster.scss';
import Button from '../../shared/buttons';
import { useImmer } from 'use-immer';
import { it } from 'faker/lib/locales';
import { useLocked } from '../../hooks/ContinueProvider';
import { useStore } from '../../../hooks/zustand';

const PopUpSlide = ({
  type,
  text,
  imgUrl,
  closeModal,
  defenderImg,
  tryDisabled,
}) => {
  switch (type) {
    case 'defender':
      return (
        <div>
          <div className='pop-up-wrap'>
            <p className='booster-text'>{text}</p>
            <div className='booster-img-wrap'>
              <img src={defenderImg} alt='defender' />
            </div>
          </div>
        </div>
      );
    case 'teacher':
      return (
        <div key={2}>
          <div className='pop-up-wrap'>
            <div className='booster-img-wrap'>
              <img src={imgUrl} alt='defender gif' />
            </div>
            <div className='booster-text'>
              <p>{text}</p>
              {tryDisabled && (
                <button onClick={closeModal} className='tons'>
                  Try Another Strategy!
                </button>
              )}
            </div>
          </div>
        </div>
      );
    default:
      return null;
  }
};

function Modal({ slides, closeModal, title, defenderImg, handleExitClick }) {
  const [slideIndex, setSlideIndex] = useState(0);
  const { allSoundsPlayed, load, isPlaying, hasAudio } = useStore((state) => {
    return {
      allSoundsPlayed: state.allSoundsPlayed,
      load: state.load,
      isPlaying: state.isPlaying,
      hasAudio: state.audioArray.length > 0,
    };
  });

  console.log(slides, 'slides', slides[slideIndex].audio);

  useEffect(() => {
    if (slides[slideIndex].audio) {
      load([
        {
          audio: slides[slideIndex].audio,
        },
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slideIndex]);

  const currentSlide = slides[slideIndex];
  return (
    <div className='greyback'>
      <div className='booster-modal' onClick={(e) => e.stopPropagation()}>
        <Title isModal>
          <h2 className='modalContentTitle mytitle centerHeader'>{title}</h2>
        </Title>
        <div className='popUp-swipe'>
          <PopUpSlide
            {...currentSlide}
            closeModal={closeModal}
            defenderImg={defenderImg}
            tryDisabled={allSoundsPlayed}
          />
          {allSoundsPlayed && slideIndex < slides.length - 1 && (
            <button
              onClick={() => {
                if (slideIndex === 0) {
                  setSlideIndex(1);
                } else {
                  closeModal();
                }
              }}
              className='next'
            >
              Next
              <RightArrow />
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

const selectionItems = [
  {
    title: 'Stealing the Show',
    teacher: {
      text: 'When you see physical bullying, don’t get directly involved. We want you to stay safe and getting involved could make the situation worse and unintendedly bring you into it. So, Stealing the Show is not a good choice for this situation. Turning it Over is the best strategy to use in this case. Now, go ahead and click on the other strategies to see how they play out. You could follow this one up with Accompanying Others or Coaching Compassion.” ',
      imgUrl: '/booster/teacher.gif',
    },
  },
  {
    title: 'Turning It Over',
    defender: {
      text: 'Defender finds male teacher, who asks defender “Hey! How’s it going?” Defender tells teacher about the physical bullying. “I really need to let you know that there’s some serious physical bullying going on.” “I have seen it several times now.” ',
      imgUrl: '/booster/defender.gif',
    },
    teacher: {
      text: 'Defender finds male teacher, who asks defender “Hey! How’s it going?” Defender tells teacher about the physical bullying. “I really need to let you know that there’s some serious physical bullying going on.” “I have seen it several times now.” ',
      imgUrl: '/booster/teacher.gif',
    },
  },
  {
    title: 'Accompanying Others',
    defender: {
      text: 'Defender tells target. “Hey. I’m headed to a class near yours. Mind if I walk with you?” “You okay? Those guys were rude.” ',
      imgUrl: '/booster/defender.gif',
    },
    teacher: {
      text: 'Accompanying Others is a good choice! After the situation is over, you can go up to your peer and let him know that you saw what happened and it was not okay. Offer to go with him to report it to a safe adult at school. Turning it Over is the best strategy for when you see physical bullying, so go ahead and click on that and the other strategies as well so that you can see how to use them in this situation. ',
      imgUrl: '/booster/teacher.gif',
    },
  },
  {
    title: 'Coaching Compassion ',
    defender: {
      text: 'Defender tells bully. “Hey, leave him alone.” “Think about what it would be like if you were him.”  ',
      imgUrl: '/booster/defender.gif',
    },
    teacher: {
      text: 'Teacher says: “If you are older, friends with the students who are engaging in the bullying, or think the students will listen to you and respect you, Coaching Compassion is a good choice. You can also just say something quick, like “Don’t do that. It’s not cool”, and often students will listen. Also, before you use Coaching Compassion, in the case of physical bullying, you should use Turning it Over. Letting an adult know when you see physical bullying is the best action you can take. Go ahead and click on the other strategies to see how they play out.” ',
      imgUrl: '/booster/teacher.gif',
    },
  },
];

function PracticeVideoModule({
  selectionItems,
  videoSrc,
  title,
  defenderImg,
  prize,
}) {
  const [showModal, setShowModal] = useState(false);
  const [slides, setSlides] = useImmer(
    selectionItems.map((item) => ({ ...item, completed: false }))
  );

  const [videoFinished, setVideoFinished] = useState(false);

  const { unlockProgram } = useLocked();

  const closeModal = () => {
    const updatedCompletedSlides = slides.map((slide, index) => {
      if (index === showModal.index) {
        return { ...slide, completed: true };
      } else {
        return slide;
      }
    });

    setSlides(updatedCompletedSlides);

    if (updatedCompletedSlides.every((slide) => slide.completed)) {
      unlockProgram();
    }

    setShowModal(false);
  };

  const getSlidesToShow = (index) => {
    const slide = slides[index];

    if (!slide.defender) {
      return [
        {
          ...slide.teacher,
          type: 'teacher',
        },
      ];
    } else {
      return [
        {
          ...slide.defender,
          type: 'defender',
        },
        {
          ...slide.teacher,
          type: 'teacher',
        },
      ];
    }
  };

  console.log(slides, 'slides');

  return (
    <>
      {showModal && (
        <Modal
          title={selectionItems[showModal.index].title}
          slides={getSlidesToShow(showModal.index)}
          closeModal={closeModal}
          handleExitClick={() => setShowModal(false)}
          defenderImg={defenderImg}
        />
      )}
      <Title isModal>
        <h1>{title}</h1>
      </Title>
      <div className='card-container split-even'>
        <div className='vid-wrap'>
          <video
            auto
            muted
            controls
            autoPlay
            onEnded={() => setVideoFinished(true)}
          >
            <source src={`${videoSrc}.mp4`} type='video/mp4' />
            {/* <source src={`${videoSrc}.webm`} type='video/webm' /> */}
          </video>
        </div>
        <div className='wrapppppp'>
          <h3>Pick a Strategy!</h3>
          <div className='intro5Box flax'>
            <ButtonBox>
              <div className='questionaireBox flex-column-even'>
                {selectionItems.map((item, index) => (
                  <Button
                    disabled={!videoFinished}
                    text={
                      <>
                        <span>{item.title}</span>
                        {slides[index].completed && (
                          <img className='check' src='/booster/check.png' />
                        )}
                      </>
                    }
                    type='options+'
                    styleName={`intro2 ${
                      slides[index].completed ? 'completed' : ''
                    }`}
                    click={() => {
                      setShowModal({
                        index: index,
                      });
                    }}
                  />
                ))}
              </div>
            </ButtonBox>
          </div>
        </div>
      </div>
    </>
  );
}
export default PracticeVideoModule;
