import React from 'react';
import Questionaire from '../../shared/Questionaire';
import { useEffect } from 'react';
import Title from '../../shared/Title';
import { gsap } from 'gsap';
import SpeechBubble from '../../shared/SpeechBubble';
import Trainer from '../../shared/Trainer';
import { FadeInBulletPoints } from '../intro/FadeInBulletPoints';
import { useLocked } from '../../hooks/ContinueProvider';
import { FadeInBulletPointsWithAudio } from '../intro/FadeInBulletPointsWithAudio';
import { useUnlockAfterAudioPlays } from '../../../hooks/useUnlockAfterAudioPlays';

function BoosterIntro() {
  useUnlockAfterAudioPlays();
  useEffect(() => {
    gsap.from('.intro2Box', {
      bottom: '-100em',
      duration: 0.5,
    });
    gsap.from('.mainNavBubble', {
      left: '1000px',
    });
  }, []);

  const { unlockProgram } = useLocked();

  return (
    <>
      <Title>
        <h1>Welcome Back!</h1>
      </Title>
      <div className='card-container'>
        <div className='flex-boost'>
          <div className='make-bigger'>
            <div className='teacherContainer'>
              <div className='mainNavBubble'>
                <SpeechBubble tween={{}}>
                  <div>Welcome back to the STAC Training!</div>
                </SpeechBubble>
              </div>
              <Trainer teacher={2} />
            </div>
          </div>
          <div className='teo'>
            <p className='today'>Today You Will</p>
            <FadeInBulletPointsWithAudio />
          </div>
        </div>
      </div>
    </>
  );
}
export default BoosterIntro;
