/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo } from 'react';
import { Navbar } from 'reactstrap';
import './booster.scss';
import Footer from '../../shared/Footer';
import { LockedProvider, useLocked } from '../../hooks/ContinueProvider';
import { Route } from 'react-router-dom/cjs/react-router-dom.min';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import NonMainHeader from '../start/NonMainHeader';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import FireWorksProvider from '../../../hooks/FireWorksProvider';
import { getCurrentPageInfo } from '../../../utils';
import { useStore } from '../../../hooks/zustand';

export const Wrapper = ({ children }) => {
  return (
    <>
      <NonMainHeader />
      {children}
    </>
  );
};

export const Booster = ({ routesArray, path }) => {
  const { section } = useParams();

  const history = useHistory();
  const currentPage = getCurrentPageInfo(section, true);
  const currentPageAudio = currentPage.info.audio;
  const { lockProgram } = useLocked();

  const { load, setDirection, eject, isLoaded } = useStore((state) => {
    return {
      load: state.load,
      eject: state.eject,
      isLoaded: state.audioArray.length > 0,
      setDirection: state.setDirection,
    };
  });

  useEffect(() => {
    console.log('section', section, currentPage);
    const shouldLockPage = currentPage.info.locked;
    console.log('shouldLockPage', shouldLockPage);

    console.log(currentPage);
    if (shouldLockPage) {
      lockProgram();
    }
  }, [section]);

  useEffect(() => {
    console.log('isLoaded', isLoaded, currentPage.info);
    if (currentPage.info.audio && !isLoaded) {
      load(currentPage.info.audio, currentPage.autoplay);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoaded]);

  useEffect(() => {
    window.addEventListener('popstate', eject);

    return () => {
      eject();
      window.removeEventListener('popstate', eject);
    };
  }, [eject]);

  //make eject get called on clicking back button

  const handleBack = () => {
    eject();
    const prevPage = routesArray[currentPage.index - 1];

    if (currentPage.index > 0) {
      setDirection('backward');
      history.push(`/booster/${prevPage.url}`);
      if (prevPage.audio) {
        load(prevPage.audio, prevPage.autoplay);
      }
    } else {
      //need to change this back to /start after trial
      history.push('/booster');
    }
  };

  const handleNext = (isFirstSlide = false) => {
    eject();
    const nextPage = routesArray[currentPage.index + 1];
    if (currentPage.index < routesArray.length - 1) {
      setDirection('forward');
      if (nextPage?.audio) {
        load(nextPage.audio, nextPage.autoplay);
      }
      history.push(`/booster/${nextPage.url}`);
    } else {
      //need to change this back to /start after trial
      history.push('/booster');
    }
  };

  return (
    <>
      <div className='mainSection'>
        <div className='activityShadow'>
          <div className='activityContainer'>
            {routesArray.map((item, index) => (
              <Route
                key={index}
                path={`/${path}/${item.url}`}
                render={() => item.component}
              />
            ))}
          </div>
        </div>
      </div>
      <Footer
        activePageNumer={currentPage.index + 1}
        totalPageNumber={routesArray.length}
        c2={routesArray}
        handleBack={handleBack}
        handleNext={handleNext}
      />
    </>
  );
};
